<template>
    <div style="padding:5px;">
        <el-form v-model="model" :inline="true">
            <el-form-item label="原始坐标类型">
                <el-select v-model="model.coordsys" class="m-2" placeholder="请选择原始坐标类型" size="small">
                    <el-option v-for="item in coordsys" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>

            <el-form-item label="坐标">
                <el-input placeholder="请输入坐标" v-model="model.locations" size="small" />
            </el-form-item>

            <el-button type="primary" size="small" @click="handleConvert">转换</el-button>

            <el-form-item label="GCJ02坐标" style="margin-left:5px;">
                <el-input v-model="model.gjc02_locations" disabled size="small" />

            </el-form-item>
            <el-button style="margin-left:5px;" type="danger" icon="el-icon-document-copy" @click="handleCopy"
                size="small">复制</el-button>

        </el-form>

    </div>
    <div style="position:relative;height:82vh;border: 2px solid #3d6eff;">
        <div class="container">
            <show-location style="width: 100%; height: 100%" ref="map"></show-location>
        </div>
    </div>


</template>
<script>
import axios from 'axios'
import { ShowLocation } from "@/components/map/components";

export default {
    setup() {

    },
    components: { ShowLocation },
    data() {
        return {
            ak: 'dc790cc4063785169ca49b8378f5a3a9',
            model: {
                locations: undefined,
                coordsys: 'gps',
                gjc02_locations: undefined,
            },
            coordsys: [
                { value: 'gps', label: 'gps' },
                { value: 'mapbar', label: 'mapbar' },
                { value: 'baidu', label: 'baidu' },
            ],


        };
    },
    methods: {
        init() {

        },
        handleConvert() {
            let url = 'https://restapi.amap.com/v3/assistant/coordinate/convert';
            axios.get(url, {
                params: {
                    key: this.ak,
                    locations: this.model.locations,
                    coordsys: this.model.coordsys,
                    output: 'json',
                }

            }).then(response => {
                const { data } = response;
                console.log(data);
                const { locations, status } = data;
                if (status == 0) {
                    this.$message({
                        type: 'error',
                        message: '请求出错'
                    })
                }
                this.model.gjc02_locations = locations;
                console.log(locations);
                this.$refs.map.showLocation(locations);

            })
        },
        handleCopy() {
            this.copyText(this.model.gjc02_locations, this);
        },
        copyText(data, el) {

            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select();// 选择对象
            document.execCommand("Copy");// 执行浏览器复制命令
            el.$message({
                message: '复制成功',
                type: 'success'
            });
            oInput.remove()
        },
    }
}
</script>
 

<style scoped>
.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}
</style>